const universalBOM = '\uFEFF'

const csvProcessRow = function (row, delimiter = ';') {
  let finalVal = ''
  for (let j = 0; j < row.length; j++) {
    let innerValue = row[j] === null ? '' : row[j].toString()
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString()
    }
    let result = innerValue.replace(/"/g, '""')
    if (result.search(/("|,|\n)/g) >= 0) {
      result = '"' + result + '"'
    }
    if (j > 0) {
      finalVal += delimiter
    }
    finalVal += result
  }
  return finalVal + '\n'
}

export function convertTableDataToCSVBlob(headers, rows) {
  const numberOfColumns = headers.length
  let csvFile = ''
  for (let i = 0; i < numberOfColumns; i++) {
    const cell = headers[i].data
    csvFile += `${cell};`
  }

  // make a new line
  csvFile += '\n'

  for (let y = 0; y < rows.length; y++) {
    if (y !== 0 && y % numberOfColumns === 0) {
      csvFile += '\n'
    }
    const cell = rows[y]

    if (cell.isDateTime) {
      csvFile += `${cell.dateTime.toISO()};`
    } else {
      //TODO: Remove this once all the Tables have been migrated to the new Tablev2
      const data = cell.data ? cell.data : cell.sortableValue
      csvFile += `${data};`
    }
  }
  const resultFile = `sep=;\n${csvFile}`
  return new Blob([universalBOM + resultFile], { type: 'text/csv;charset=utf-8;' })
}

export function exportToCsv(filename, rows, delimiter) {
  let csvFile = ''
  for (let i = 0; i < rows.length; i++) {
    csvFile += csvProcessRow(rows[i], delimiter)
  }
  downloadCSVFile(csvFile, filename)
}

export function exportToBlob(rows, delimiter) {
  let csvFile = ''
  for (let i = 0; i < rows.length; i++) {
    csvFile += csvProcessRow(rows[i], delimiter)
  }
  csvFile = `sep=;\n${csvFile}`
  return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
}

export function downloadCSVFile(csvFile, filename) {
  const blob = new Blob([universalBOM + csvFile], { type: 'text/csv;charset=utf-8' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export function exportTableToCSVBlob(tableDiv) {
  const exporter = new TableCSVExporter(tableDiv)
  const csvOutput = exporter.convertToCSV()
  const csvBlob = new Blob([universalBOM + csvOutput], { type: 'text/csv' })
  return csvBlob
}

export function exportTableDataToCSV(headers, rows, fileName) {
  const csvBlob = convertTableDataToCSVBlob(headers, rows)
  const blobUrl = URL.createObjectURL(csvBlob)
  const anchorElement = document.createElement('a')

  anchorElement.href = blobUrl
  anchorElement.download = `${fileName}.csv`
  anchorElement.click()
}

class TableCSVExporter {
  constructor(table, includeHeaders = true) {
    this.table = table
    this.rows = Array.from(table.querySelectorAll('tr'))

    if (!includeHeaders && this.rows[0].querySelectorAll('th').length) {
      this.rows.shift()
    }
  }

  convertToCSV() {
    const lines = []
    const numCols = this._findLongestRowLength()

    for (const row of this.rows) {
      let line = ''

      for (let i = 0; i < numCols; i++) {
        if (row.children[i] !== undefined) {
          const cell = TableCSVExporter.parseCell(row.children[i])
          line += cell
        }

        line += i !== numCols - 1 ? ',' : ''
      }

      lines.push(line)
    }

    return lines.join('\n')
  }

  _findLongestRowLength() {
    return this.rows.reduce((l, row) => (row.childElementCount > l ? row.childElementCount : l), 0)
  }

  static parseCell(tableCell) {
    let parsedValue = tableCell.textContent

    // Replace all double quotes with two double quotes
    parsedValue = parsedValue.replace(/"/g, `""`)

    // If value contains comma, new-line or double-quote, enclose in double quotes
    parsedValue = /[",\n]/.test(parsedValue) ? `"${parsedValue}"` : parsedValue

    return parsedValue
  }
}
